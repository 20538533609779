<template>
  <ion-page>
    <LoginRegisterNav :valueInicial="'inicia'" />

    <ion-content>
      <div class="contenedor">
        <div class="centerCenter" style="padding: 30px 30px 10px 30px">
          <span class="parrafo">
            Ingresa a través de tu cuenta de Facebook, Google o Apple.
          </span>
        </div>

        <SocialButtons />

        <div class="centerCenter" style="margin: 15px 0 5px 0">
          <span class="parrafo-small"> O si ya tienes cuenta en GRAMOS </span>
        </div>
      </div>

      <LoginForm />
    </ion-content>
  </ion-page>
</template>

<script >
import { IonPage, IonContent } from "@ionic/vue";
import LoginRegisterNav from "./components/LoginRegisterNav";
import SocialButtons from "./components/SocialButtons";
import LoginForm from "./components/LoginForm";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Login",
  components: {
    SocialButtons,
    LoginRegisterNav,
    LoginForm,
    IonPage,
    IonContent
  }
});
</script>

<style scoped>

  ion-content {
    --background: #fdf2ec;
    text-align: -webkit-center;
  }

  ion-footer {
    background-color: #fdf2ec;
    border: 0px !important;
  }
</style>
