<template>
    <div class="contenedor">

       <form @submit.prevent="submitForm" novalidate>
         <ion-item mode="md">
            <ion-label position="floating" class="labelText">Email</ion-label>
            <ion-input
              type="email"
              name="emailAddress"
              v-model="vv.emailAddress.$model"
            />
          </ion-item>
          <p
            class="error-msg"
            v-if="
              vv?.emailAddress?.$errors[0]?.$message === 'Value is required'
            "
          >
            Email requerido
          </p>
          <p
            class="error-msg"
            v-if="
              vv?.emailAddress?.$errors[0]?.$message ===
              'Value is not a valid email address'
            "
          >
            Dirección de email invalida
          </p>


          <ion-item mode="md">
            <ion-label position="floating" class="labelText"
              >Contraseña</ion-label
            >
            <ion-input
              :type="passwordFieldType"
              name="password"
              v-model="vv.password.$model"
            />
            <ion-icon
              slot="end"
              :icon="passwordEyeStyle"
              @click="switchPasswordVisibility"
              style="margin-top: 20px"
            ></ion-icon>
          </ion-item>
          <p
            class="error-msg"
            v-if="vv?.password?.$errors[0]?.$message === 'Value is required'"
          >
            Contraseña requerida
          </p>
          <p
            class="error-msg"
            v-if="vv?.password?.$errors[0]?.$message === 'This field should be at least 8 long'"
          >
            Debe tener un mínimo de 8 caracteres.
          </p>

          <ion-button
            type="submit"
            class="btn buttonIniciar"
            expand="block"
            fill="solid"
            :disabled="
              vv.password.$errors.length != 0 ||
              vv.emailAddress.$errors.length != 0 ||
              vv?.password.$dirty == false ||
              vv?.emailAddress.$dirty == false
            "
            >INICIAR SESIÓN</ion-button
            >
            <!-- EL siguiente boton existe solo para poder enviar el form haciendo "enter"
            ya que el "ion-button" no puede hacer eso debido a una complicacion con el shadow DOM
            NO BORRAR
             -->
            <button 
              style="display: none" 
              :disabled="
              vv.password.$errors.length != 0 ||
              vv.emailAddress.$errors.length != 0 ||
              vv?.password.$dirty == false ||
              vv?.emailAddress.$dirty == false
            "
              ></button>
       </form>

       <div class="centerCenter" style="margin: 15px 0 5px 0">
            <span class="parrafo-small">
                ¿Olvidaste tu contraseña?
                <span
                style="color: #e33613; font-weight: bold"
                v-on:click="goToRecover"
                >RECUPERAR</span
                >
            </span>
        </div>
          
    </div>
</template>

<script >
import { IonLabel, IonInput, IonItem, IonButton, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../../router/index";
import { reactive, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import AccountService from '../../../services/account.service';


export default defineComponent({
  name: "LoginForm",
  components: {
    IonLabel, 
    IonInput, 
    IonItem, 
    IonButton,
    IonIcon
  },
  data() {
    return {
        passwordFieldType: "password",
        passwordEyeStyle: eyeOffOutline
    };
  },
  setup() {
    const fform = reactive({
      emailAddress: "",
      password: "",
    });

    const rules = {
      emailAddress: { required, email },
      password: { required, minLength: minLength(8) },
      save: {},
    };
    const vv = useVuelidate(rules, {
      emailAddress: toRef(fform, "emailAddress"),
      password: toRef(fform, "password"),
      save: toRef(fform, "save"),
    });

    const submitForm = () => {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      AccountService.loginRequest({ 
        email: fform.emailAddress,
        password: fform.password 
      }).then((status) => {
        const path = status === 200 ? "/onboarding/initial" : "/register";
        router.replace(path);
      })
    };

    return {
      submitForm,
      vv,
      eyeOutline,
      eyeOffOutline
    };
  },
  methods: {
    goToRecover() {
      router.replace("recuperarPassword");
    },
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordEyeStyle =
        this.passwordEyeStyle === eyeOffOutline ? eyeOutline : eyeOffOutline;
    },
  },
});
</script>

<style scoped>
ion-item {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  --background: white;
  margin-top: 10px;
  --highlight-color-focused: #d4692700;
}

ion-item.item-has-focus {
  border-bottom: 3px solid #d46927;
}

ion-item.item-has-focus > ion-label {
  color: #d46827 !important;
}

</style>
